import React from 'react';

import { buildImageObj, slugToUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Anchor,
  Text,
  Image,
  Heading
} from 'grommet';
import OverlayCard from '../overlay-card/overlay-card';

const WorldPreview = ({ mainImage, title, slug, foundedDate, urlType }) => {
  return (
    <OverlayCard {...{ mainImage, title, slug, urlType }} ratio={1}>
      <Text size="small">Founded: {foundedDate}</Text>
    </OverlayCard>
  );
};

export default WorldPreview;
