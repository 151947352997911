import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers';
import GraphQLErrorList from '../components/graphql-error-list/graphql-error-list';
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import CenterColumn from '../components/center-column/center-column';
import WorldsList from '../components/worlds-list/worlds-list';
import { Heading, Text } from 'grommet';
import PlacesList from '../components/places-list/places-list';
import MinecraftPlacePreview from '../components/minecraft-place-preview/minecraft-place-preview';

export const query = graphql`
  query minecraftWorldsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    places: allSanityMinecraftPlace(
      limit: 2
      sort: { fields: featuredWeight }
    ) {
      edges {
        node {
          id
          title
          xCoordinate
          zCoordinate
          yCoordinate
          mainImage {
            ...Image
          }
          slug {
            current
          }
        }
      }
    }
    worlds: allSanityMinecraftWorld {
      edges {
        node {
          id
          title
          seed
          foundedDate
          mainImage {
            ...Image
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const MinecraftPage = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const worlds = (data || {}).worlds
    ? mapEdgesToNodes(data.worlds).filter(filterOutDocsWithoutSlugs)
    : [];

  const places = (data || {}).places
    ? mapEdgesToNodes(data.places).filter(filterOutDocsWithoutSlugs)
    : [];

  return (
    <Layout>
      <SEO
        title={`${site.title} - Minecraft`}
        description={`The landing page for Minecraft things`}
        keywords={site.keywords}
      />

      <Hero
        imageUrl="/images/minecraft-landscape.png"
        subtitle="Minecraft is a family favorite. "
        title="Minecraft"
      />
      <CenterColumn>
        <Heading level="2">Worlds</Heading>
        <Text>Pick a world to see its places and map.</Text>
        {worlds && <WorldsList worlds={worlds} urlType="minecraftWorld" />}
        <Heading level="2">Places</Heading>
        <PlacesList
          places={places}
          PlacePreviewComponent={MinecraftPlacePreview}
        />
      </CenterColumn>
    </Layout>
  );
};

export default MinecraftPage;
