import { Box } from 'grommet';
import React from 'react';
import { ResponsiveContext } from 'grommet';

const CenterColumn = ({ children }) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box
      width="large"
      alignSelf="center"
      margin={{ vertical: 'large' }}
      pad={size === 'small' || size === 'medium' ? 'medium' : 'none'}
    >
      {children}
    </Box>
  );
};

export default CenterColumn;
