import React from 'react';

import { buildImageObj, slugToUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import { Link } from 'gatsby';

import { Compass } from 'grommet-icons';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chart,
  Grid,
  Anchor,
  Heading,
  Text,
  Box,
  Image
} from 'grommet';
import OverlayCard from '../overlay-card/overlay-card';

const MinecraftPlacePreview = ({
  mainImage,
  title,
  slug,
  xCoordinate,
  zCoordinate
}) => {
  return (
    <OverlayCard {...{ mainImage, title, slug, urlType: 'minecraftPlace' }}>
      <Box direction="row">
        <Box pad={{ right: 'small' }}>
          <Compass />
        </Box>
        <Text>
          X: {xCoordinate} / Z: {zCoordinate}
        </Text>
      </Box>
    </OverlayCard>
  );
};

export default MinecraftPlacePreview;
