import { Grommet, Text } from 'grommet';
import React from 'react';

const theme = {
  text: {
    font: {
      family: undefined
    }
  }
};

const SansSerifText = ({ children }) => (
  <Grommet theme={theme}>
    <Text size="large" weight="bold">
      {children}
    </Text>
  </Grommet>
);

export default SansSerifText;
