import React from 'react';

import { buildImageObj } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

import { Box, Heading, Text } from 'grommet';

import SansSerifText from '../text/sans-serif-text';

const Hero = ({
  mainImage,
  title,
  imageUrl,
  level,
  text,
  subtitle,
  children
}) => {
  imageUrl =
    imageUrl ||
    imageUrlFor(buildImageObj(mainImage))
      .width(2000)
      .height(Math.floor((9 / 45) * 2000))
      .fit('crop')
      .auto('format')
      .url();

  return (
    <Box
      justify={subtitle ? 'center' : 'end'}
      height="50vh"
      background={{
        image: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${imageUrl})`,
        size: 'cover',
        position: 'center'
      }}
    >
      <Box pad="large" align="center" width="100%">
        <Box width="medium">
          <Heading
            level={level}
            textAlign={text || subtitle ? 'center' : 'start'}
            margin={{ bottom: 'medium' }}
          >
            {title}
          </Heading>
          {subtitle && (
            <Text textAlign="center" size="large">
              <em>{subtitle}</em>
            </Text>
          )}

          {text && (
            <Box
              background={{
                color: 'neutral-2'
              }}
              pad="medium"
              width="medium"
              align="center"
            >
              <SansSerifText>{text}</SansSerifText>
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
