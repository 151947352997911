import React from 'react';
import { Link } from 'gatsby';
import WorldPreview from '../world-preview/world-preview';
import { ResponsiveContext } from 'grommet';

import { Grid } from 'grommet';

const WorldsList = ({ worlds, urlType }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Grid
      gap="small"
      columns={{ count: size === 'small' ? 1 : 2, size: 'small' }}
      margin={{ bottom: 'large' }}
    >
      {worlds &&
        worlds.map((world) => <WorldPreview {...world} urlType={urlType} />)}
    </Grid>
  );
};

WorldsList.defaultProps = {
  worlds: []
};

export default WorldsList;
